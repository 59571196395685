import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import MapDetail from './gmap/MapDetail'

export default function DetailModal(props) {
  const { show, setShow, detailData } = props
  //const [show, setShow] = useState(false)
  //console.log(detailData)

  const splitAddress = (address, township) => {
    const addressArray = address.split(township)
    if (addressArray.length > 1) {
      return addressArray
    }
    return ['', ...addressArray]
  }

  let addressTitle = ''
  let addressTownship = ''
  if (detailData) {
    // 20240131 use address_display to replace address
    // 20240201 tmp fix for undefined address_display
    const addressArray = splitAddress(
      detailData.address_display.includes('undefined')
        ? detailData.address
        : detailData.address_display,
      detailData.township
    )
    addressTitle = addressArray[1]
    addressTownship = addressArray[0] + detailData.township
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      {detailData && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="unit">{addressTownship}</div>
              <h4 className="">{addressTitle}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MapDetail lat={+detailData.lat} lng={+detailData.lng} />
            <table className="table table-condensed">
              <tbody>
                <tr>
                  <th>交易日期</th>
                  <td className="">
                    {detailData.transaction_date.substr(0, 7).split('-')[0]} 年{' '}
                    {detailData.transaction_date.substr(0, 7).split('-')[1]} 月
                  </td>
                </tr>
                <tr>
                  <th>總價</th>
                  <td>
                    <span className="price">
                      {(+detailData.total_price / 10000).toFixed(1)}
                    </span>
                    <span className="unit pl-1">萬</span>
                  </td>
                </tr>
                <tr>
                  <th>單價</th>
                  <td>
                    <span className="price">
                      {(+detailData.unit_price / 10000).toFixed(2)}
                    </span>
                    <span className="unit pl-1">萬/坪</span>
                  </td>
                </tr>
                <tr>
                  <th>建物移轉總面積</th>
                  <td className="ng-binding">
                    {(+detailData.total_transfer_area).toFixed(2)}
                    <span className="unit pl-1">坪</span>
                  </td>
                </tr>
                <tr>
                  <th>土地移轉面積</th>
                  <td className="ng-binding">
                    {(+detailData.total_area).toFixed(2)}
                    <span className="unit pl-1">坪</span>
                  </td>
                </tr>
                <tr>
                  <th>交易標的</th>
                  <td>{detailData.subject}</td>
                </tr>
                <tr className="">
                  <th>使用分區或編定</th>
                  <td className="">{detailData.use_partition}</td>
                </tr>

                <tr>
                  <th>移轉層次 / 總樓層數</th>
                  <td className="ng-binding">
                    {detailData.transfer_hierarchy} / {detailData.total_floors}
                  </td>
                </tr>
                <tr>
                  <th>交易筆棟數</th>
                  <td>{detailData.number_of_transaction}</td>
                </tr>
                <tr>
                  <th>建物型態</th>
                  <td className="">{detailData.building_type}</td>
                </tr>
                <tr>
                  <th>主要用途</th>
                  <td className="">{detailData.main_purpose}</td>
                </tr>
                <tr>
                  <th>主要建材</th>
                  <td className="">{detailData.main_building_material}</td>
                </tr>
                <tr>
                  <th>建築完成日期</th>
                  <td className="">
                    {Number(
                      detailData.completion_date.substr(0, 7).split('-')[0]
                    ) < 1911 ? (
                      ''
                    ) : (
                      <>
                        {detailData.completion_date.substr(0, 7).split('-')[0]}{' '}
                        年{' '}
                        {detailData.completion_date.substr(0, 7).split('-')[1]}{' '}
                        月
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>建築現況格局</th>
                  <td>
                    <span className="grouped">
                      {detailData.current_structure_room} 房
                    </span>{' '}
                    <span className="grouped">
                      {detailData.current_structure_hall} 廳
                    </span>{' '}
                    <span className="grouped">
                      {detailData.current_structure_bathroom} 衛
                    </span>
                  </td>
                </tr>
                {detailData.parking_space_type ? (
                  <tr className="">
                    <th>車位類別</th>
                    <td className="">{detailData.parking_space_type}</td>
                  </tr>
                ) : (
                  ''
                )}
                {+detailData.parking_space_total_price ? (
                  <tr className="">
                    <th>車位總價</th>
                    <td className="">
                      {(+detailData.parking_space_total_price / 10000).toFixed(
                        2
                      )}
                      <span className="unit pl-1">萬元</span>
                    </td>
                  </tr>
                ) : (
                  ''
                )}
                {+detailData.parking_space_total_area ? (
                  <tr ng-show="record.parking_area" className="">
                    <th>車位移轉總面積</th>
                    <td className="ng-binding">
                      {(+detailData.parking_space_total_area).toFixed(2)}
                      <span className="unit pl-1">坪</span>
                    </td>
                  </tr>
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              關閉
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
