import React from 'react'
import { Badge } from 'react-bootstrap'

export default function NewsFlash() {
  return (
    <>
      <div className="container">
        <div className="mt-4 mb-4">
          <div className="tickerv-wrap">
            <ul>
              <li key={0}>
                <Badge variant="danger">快訊</Badge> 已經更新資料至2024/9/1 (共
                2,890,291 筆)。
              </li>
              <li key={1}>
                <Badge variant="danger">微課堂</Badge>{' '}
                <a
                  href="https://house123.com.tw/%E5%BE%AE%E8%AA%B2%E5%A0%82/956-%E3%80%8C%E7%89%A9%E6%B5%81%E7%AE%B1%E3%80%8D%E6%98%AF%E6%90%AC%E5%AE%B6%E7%A5%9E%E5%99%A8%EF%BC%9F%EF%BD%9C%E9%82%B1%E6%84%9B%E8%8E%89-house123"
                  target="_blank"
                  rel="noreferrer"
                >
                  「物流箱」是搬家神器？
                </a>
              </li>
              <li key={2}>
                <Badge variant="danger">微課堂</Badge>
                <a
                  href="https://house123.com.tw/%E5%BE%AE%E8%AA%B2%E5%A0%82/955-%E6%88%91%E8%88%87%E3%80%8C%E8%B2%B7%E6%88%BF%E3%80%8D%E7%9A%84%E8%B7%9D%E9%9B%A2%EF%BC%9A%E5%B0%8F%E8%B3%87%E6%97%8F%E8%B3%BC%E6%88%BF%E8%A6%81%E8%80%83%E6%85%AE%E4%BB%80%E9%BA%BC%EF%BC%9F%EF%BD%9C%E9%82%B1%E6%84%9B%E8%8E%89-house123"
                  target="_blank"
                  rel="noreferrer"
                >
                  我與「買房」的距離：小資族購房要考慮什麼？
                </a>
              </li>
              <li key={3}>
                <Badge variant="danger">微課堂</Badge>
                <a
                  href="https://house123.com.tw/%E5%BE%AE%E8%AA%B2%E5%A0%82/955-%E6%88%91%E8%88%87%E3%80%8C%E8%B2%B7%E6%88%BF%E3%80%8D%E7%9A%84%E8%B7%9D%E9%9B%A2%EF%BC%9A%E5%B0%8F%E8%B3%87%E6%97%8F%E8%B3%BC%E6%88%BF%E8%A6%81%E8%80%83%E6%85%AE%E4%BB%80%E9%BA%BC%EF%BC%9F%EF%BD%9C%E9%82%B1%E6%84%9B%E8%8E%89-house123"
                  target="_blank"
                  rel="noreferrer"
                >
                  你還在花大錢做用不到的裝潢嗎？
                </a>
              </li>
              <li key={4}>
                <Badge variant="danger">微課堂</Badge>
                <a
                  href="https://house123.com.tw/%E5%BE%AE%E8%AA%B2%E5%A0%82/953-%E9%80%993%E7%A8%AE%E5%9C%B0%E9%9B%B7%E5%B1%8B%EF%BC%8C%E4%BD%A0%E4%B8%80%E5%AE%9A%E8%A6%81%E7%9F%A5%E9%81%93-%EF%BC%81%EF%BC%81%EF%BC%81%EF%BD%9C%E9%82%B1%E6%84%9B%E8%8E%89-house123"
                  target="_blank"
                  rel="noreferrer"
                >
                  這3種地雷屋，你一定要知道 ！！！
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
